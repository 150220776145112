<template>
  <div>
    <b-card class="mb-5" no-body>
      <b-card-header class="p-1" header-tab="header" role="tab">
        <b-button v-b-toggle.accordion-2 block class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon"
                  variant="link">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">


              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.customers') }}</label>
                <multiselect v-model="customers_ids"
                             :multiple="true"
                             :options="customers"
                             :placeholder="$t('customer_transactions_report.customers')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getIds('customers')"
                             @search-change="getCustomers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.currency') }}</label>
                <select id="currency_id" v-model="filters.currency_id" class="custom-select" name="">
                  <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
                </select>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.branches') }}</label>
                <multiselect v-model="branches_ids"
                             :multiple="true"
                             :options="branches"
                             :placeholder="$t('customer_transactions_report.branches')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getIds('branches')">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.inventories') }}</label>
                <multiselect v-model="inventories_ids"
                             :multiple="true"
                             :options="inventories"
                             :placeholder="$t('customer_transactions_report.inventories')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getIds('inventories')">
                </multiselect>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.users') }}</label>
                <multiselect
                    v-model="user"
                    :multiple="false"
                    :options="users"
                    :placeholder="$t('customer_transactions_report.users')"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    :taggable="false"
                    label="name"
                    track-by="id"
                    @input="getIds('users')">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-10 mb-2">
                <b-form-checkbox id="only_invoices" v-model="filters.only_invoices" :unchecked-value="0"
                                 :value="1" name="only_invoices" @input="changeListOfInvoiceType">
                  {{ $t('only_invoices') }}
                </b-form-checkbox>

              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.invoice_type') }}</label>
                <select id="invoice_type" v-model="filters.invoice_type" class="custom-select" name="">
                  <option v-for="row in invoice_type_list" :key="row.key" :disabled="row.disabled" :value="row.key">
                    {{ row.name }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="code">{{ $t('customer_transactions_report.code') }}</label>
                <input id="code" v-model="filters.code" class="form-control" type="text">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.status') }}</label>
                <multiselect
                    v-model="status_ids"
                    :multiple="true"
                    :options="status_list"
                    :placeholder="$t('customer_transactions_report.status')"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    :taggable="false"
                    label="title"
                    track-by="id"
                    @input="getIds('status')">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-10 mb-2">
                <b-form-checkbox id="all_customers" v-model="filters.is_all_customers" :unchecked-value="0"
                                 :value="1" name="all_customers" @input="getAllIds('customers')">
                  {{ $t('customer_transactions_report.all_customers') }}
                </b-form-checkbox>
                <b-form-checkbox id="all_branches" v-model="filters.is_all_branches" :unchecked-value="0"
                                 :value="1" name="all_branches" @input="getAllIds('branches')">
                  {{ $t('customer_transactions_report.all_branches') }}
                </b-form-checkbox>

              </div>

              <!--              <div class="form-group col-md-6 mt-2 mb-2">-->
              <!--                <label>{{ $t('customer_transactions_report.payment_method') }}</label>-->
              <!--                <select name="" id="payment_method" v-model="filters.payment_method" class="custom-select">-->
              <!--                  <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>-->
              <!--                </select>-->
              <!--              </div>-->

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('customer_transactions_report.from_date') }}</label>
                <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('customer_transactions_report.to_date') }}</label>
                <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
              </div>


              <!--                <div class="form-group col-md-3 mt-2 mb-2">-->
              <!--                  <label>{{ $t('customer_transactions_report.interval_type') }}</label>-->
              <!--                  <select name="" id="period" v-model="filters.period" @input="changeIntervalType" class="custom-select">-->
              <!--                    <option v-for="row in period_date" :value="row" :key="row">{{ $t(row) }}</option>-->
              <!--                  </select>-->
              <!--                </div>-->
              <!--                <div class="form-group col-md-3 mt-2 mb-2">-->
              <!--                  <label>{{ $t('customer_transactions_report.interval_no') }}</label>-->
              <!--                  <select name="" id="range" v-model="filters.range" class="custom-select">-->
              <!--                    <option v-for="row in period_counter" :value="row" :key="row">{{ row }}</option>-->
              <!--                  </select>-->
              <!--                </div>-->
              <!--              <div class="col-md-6 mt-2 mb-2 pt-10">-->
              <!--                <div class="d-flex justify-space-between">-->
              <!--                  <b-form-radio id="current_date" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="day">-->
              <!--                    {{$t('customer_transactions_report.current_date')}}-->
              <!--                  </b-form-radio>-->
              <!--                  <b-form-radio id="current_week" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="week">-->
              <!--                    {{$t('customer_transactions_report.current_week')}}-->
              <!--                  </b-form-radio>-->
              <!--                  <b-form-radio id="current_month" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="month">-->
              <!--                    {{$t('customer_transactions_report.current_month')}}-->
              <!--                  </b-form-radio>-->
              <!--                  <b-form-radio id="current_year" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="year">-->
              <!--                    {{$t('customer_transactions_report.current_year')}}-->
              <!--                  </b-form-radio>-->
              <!--                  <b-form-checkbox id="financial_year" v-model="filters.financial_year" class="w-100"  name="financial_year" :value="1" :unchecked-value="0">-->
              <!--                    {{$t('customer_transactions_report.financial_year')}}-->
              <!--                  </b-form-checkbox>-->
              <!--&lt;!&ndash;                  <b-form-radio id="financial_year" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="financial_year">&ndash;&gt;-->
              <!--&lt;!&ndash;                    {{$t('customer_transactions_report.financial_year')}}&ndash;&gt;-->
              <!--&lt;!&ndash;                  </b-form-radio>&ndash;&gt;-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button :variant="filters.period=='current_day'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('current_day')">
                      {{ $t('customer_transactions_report.current_date') }}
                    </b-button>
                    <b-button :variant="filters.period=='current_month'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('current_month')">
                      {{ $t('customer_transactions_report.current_month') }}
                    </b-button>
                    <b-button :variant="filters.period=='current_year'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('current_year')">
                      {{ $t('customer_transactions_report.current_year') }}
                    </b-button>
                    <b-button :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('financial_year')">
                      {{ $t('customer_transactions_report.financial_year') }}
                    </b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>
                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('yesterday')">
                      {{ $t('customer_transactions_report.yesterday') }}
                    </b-button>
                    <b-button :variant="filters.period=='last_month'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('last_month')">
                      {{ $t('customer_transactions_report.last_month') }}
                    </b-button>
                    <b-button :variant="filters.period=='last_year'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('last_year')">
                      {{ $t('customer_transactions_report.last_year') }}
                    </b-button>
                  </b-button-group>
                </div>
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                  {{ $t('display_report') }}
                </button>
                <button id="resetFilter" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <div class="mt-3">
          <div class="row mb-10">
            <div id="summaryTableDetailsTitle" class="col-md-12 mt-10">
              <h4 class="text-center">{{ $t('customer_transactions_report.customer_transactions_report') }}</h4>
              <h5 v-if="filters.from_date && filters.to_date" class="text-center">{{ $t('from') }} {{
                  filters.from_date
                }} {{ $t('to') }} {{ filters.to_date }}</h5>
            </div>
          </div>
          <div class="row mb-10">
            <div v-if="data_report_details" class="col-12">
              <div class="table-responsive">
                <div class="row justify-content-end p-4">

                  <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
                    <button class="dropdown-item" @click="getDataForExport('summaryTableDetails', 'excel')">
                      <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="printData('summaryTableDetails')">
                      <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
                      <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                      <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                    </button>

                  </b-dropdown>
                </div>
                <table id="summaryTableDetails" class="table table-bordered">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('customer_transactions_report.date_time') }}</th>
                    <th>{{ $t('customer_transactions_report.doc_type') }}</th>
                    <th>{{ $t('customer_transactions_report.code') }}</th>
                    <th>{{ $t('customer_transactions_report.branch') }}</th>
                    <th>{{ $t('customer_transactions_report.inventory') }}</th>
                    <th>{{ $t('customer_transactions_report.customer') }}</th>
                    <th>{{ $t('customer_transactions_report.employee') }}</th>
                    <th>{{ $t('customer_transactions_report.status') }}</th>
                    <th>{{ $t('customer_transactions_report.amount') }}</th>
                    <th>{{ $t('customer_transactions_report.status_paid') }}</th>


                    <!--                    <th>{{ $t('customer_transactions_report.currency') }}</th>-->
                    <!--                    <th>{{ $t('customer_transactions_report.amount') }}</th>-->
                    <!--                    <th>{{ $t('customer_transactions_report.exchange_rate') }}</th>-->
                    <!--                    <th>{{ $t('customer_transactions_report.doc_number') }}</th>-->
                    <!--                    <th>{{ $t('customer_transactions_report.doc_type') }}</th>-->
                    <!--                    <th>{{ $t('customer_transactions_report.balance_before') }}</th>-->
                    <!--                    <th>{{ $t('customer_transactions_report.balance_after') }}</th>-->
                    <!--                    <th>{{ $t('customer_transactions_report.wanted_amount') }}</th>-->
                    <!--                    <th>{{ $t('customer_transactions_report.paid_amount') }}</th>-->
                  </tr>
                  </thead>
                  <tbody>

                  <template v-if="data_report_details">
                    <tr v-for="(row, index) in data_report_details" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ row.created_at }}</td>
                      <td>{{ row.doc_type }}</td>
                      <td>{{ row.code }}</td>
                      <td>{{ row.branch }}</td>
                      <td>{{ row.inventory }}</td>
                      <td>{{ row.customer }}</td>
                      <td>{{ row.user }}</td>
                      <td>{{ row.status }}</td>
                      <td>{{ row.amount }}</td>
                      <td>{{ row.is_paid_status_name }}</td>


                      <!--                      <td>{{ row.currency_name }}</td>-->
                      <!--                      <td>{{ row.amount }}</td>-->
                      <!--                      <td>{{ row.exchange_rate }}</td>-->
                      <!--                      <td>{{ row.type_id }}</td>-->
                      <!--                      <td>{{ row.doc_type }}</td>-->
                      <!--                      <td>{{ row.balance_before }}</td>-->
                      <!--                      <td>{{ row.balance_after }}</td>-->
                      <!--                      <td>{{ row.wanted_amount }}</td>-->
                      <!--                      <td>{{ row.paid_amount }}</td>-->
                    </tr>
                  </template>


                  </tbody>
                </table>
              </div>
              <div v-if="current_page < last_page" class="text-center">
                <button class="btn btn-warning" @click="loadMore">
                  <p class="mb-0">
                    <b-icon font-scale="1" icon="arrow-counterclockwise"></b-icon>
                    {{ $t('load_more') }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--end::customer-->
    <div id="selectorExport" class="selector-export"></div>
    <div class="export-container">
      <div class="section-for-export">
        <table id="sectionForExport" class="table table-bordered">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('customer_transactions_report.date_time') }}</th>
            <th>{{ $t('customer_transactions_report.doc_type') }}</th>
            <th>{{ $t('customer_transactions_report.code') }}</th>
            <th>{{ $t('customer_transactions_report.branch') }}</th>
            <th>{{ $t('customer_transactions_report.inventory') }}</th>
            <th>{{ $t('customer_transactions_report.customer') }}</th>
            <th>{{ $t('customer_transactions_report.employee') }}</th>
            <th>{{ $t('customer_transactions_report.status') }}</th>
            <th>{{ $t('customer_transactions_report.amount') }}</th>
            <th>{{ $t('customer_transactions_report.status_paid') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(row, index) in dataList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ row.created_at }}</td>
            <td>{{ row.doc_type }}</td>
            <td>{{ row.code }}</td>
            <td>{{ row.branch }}</td>
            <td>{{ row.inventory }}</td>
            <td>{{ row.customer }}</td>
            <td>{{ row.user }}</td>
            <td>{{ row.status }}</td>
            <td>{{ row.amount }}</td>
            <td>{{ row.is_paid_status_name }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div id="sectionForExportHeader" class="section-for-export">
        <report-main-header :print-type="'CustomerTransactionsReport'"></report-main-header>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";

export default {
  name: "index-customer-transactions-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/customer-report/balance_history',
      mainRouteDependency: 'base/dependency',

      currencies: [],

      // currency_name
      // amount
      // exchange_rate
      // doc_type
      // doc_title
      // balance_before
      // balance_after
      // wanted_amount
      // paid_amount


      data_report_details: [],
      dataList: [],

      filters: {
        customers_ids: [],
        branches_ids: [],
        inventories_ids: [],
        user_id: null,
        status_ids: [],
        currency_id: null,
        from_date: null,
        to_date: null,
        invoice_type: null,
        code: null,
        payment_method: null,
        is_all_customers: 1,
        is_all_branches: 1,

        period: null,
        range: null,
        f_time_period: 0,
        only_invoices: 0,
        financial_year: 0,
      },
      customers_ids: [],
      branches_ids: [],
      inventories_ids: [],
      user: null,
      status_ids: [],
      payment_methods: [],

      customers: [],
      branches: [],
      users: [],
      status_list: [],
      inventories: [],

      page: 0,
      current_page: 0,
      last_page: 0,

      invoice_type_list: [
        {key: 'sales_invoice', name: this.$t('invoices_types_trans.sales_invoice'), disabled: false},
        {key: 'general_sales_invoice', name: this.$t('invoices_types_trans.general_sales_invoice'), disabled: false},
        {key: 'pos', name: this.$t('invoices_types_trans.pos'), disabled: false},
        {key: 'sales_refund', name: this.$t('invoices_types_trans.sales_refund'), disabled: false},
        {key: 'sales_credit', name: this.$t('invoices_types_trans.sales_credit'), disabled: false},
        {key: 'sales_debit', name: this.$t('invoices_types_trans.sales_debit'), disabled: false},
        {key: 'general_sales_refund', name: this.$t('invoices_types_trans.general_sales_refund'), disabled: false},
        {key: 'general_sales_credit', name: this.$t('invoices_types_trans.general_sales_credit'), disabled: false},
        {key: 'general_sales_debit', name: this.$t('invoices_types_trans.general_sales_debit'), disabled: false},
        {key: 'sales_payment', name: this.$t('invoices_types_trans.sales_payment'), disabled: false},
        {
          key: 'expenses_income_transaction',
          name: this.$t('invoices_types_trans.expenses_income_transaction'),
          disabled: false
        },
      ],
      period_date: ['day', 'week', 'month', 'year'],
      period_numbers: {
        day: 31,
        week: 4,
        month: 12,
        year: 10,
      },
      period_counter: [],
      inner_period: null,
      currency_default_id: null,
      branch_default: null,
    }
  },
  watch: {},
  beforeMount() {
    this.defaultDataForUser();
    this.getCurrencies();
    this.getBranches();
    this.getPaymentMethods();
    this.getStatus();
    this.getInventories();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"),route:'/clients/reports'}, {title: this.$t("MENU.customer_transactions_report")}]);
    this.getUsers();
    },
  methods: {
    ...cssStypeForPrintReport,
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    doFilter() {
      this.page = 1;
      this.getReportDetails();

    },
    resetFilter() {
      this.filters.currency_id = this.currency_default_id;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.invoice_type = null;
      this.filters.code = null;
      this.filters.payment_method = null;
      this.filters.is_all_customers = 1;
      this.filters.is_all_branches = 1;
      this.filters.only_invoices = 0;

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;

      this.filters.customers_ids = [];
      this.filters.branches_ids = [];
      this.filters.user_id = [];
      this.filters.status_ids = [];
      this.filters.inventories_ids = [];

      this.customers_ids = []
      this.branches_ids = []
      this.user = null
      this.status_ids = [];
      this.inventories_ids = [];

      this.status_ids = this.status_list.filter((row) => row.id == 2 || row.id == 5);
      this.filters.status_ids = [];
      this.getIds('status');

      this.inner_period = null;

      // if(this.branch_default){
      //   this.branches_ids.push(this.branch_default);
      //   this.getIds('branches');
      // }
      this.doFilter();
    },

    async getCustomers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    getUsers() {
        ApiService.get(`${this.mainRouteDependency}/users`).then((response) => {
          this.users = response.data.data;
        });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.filters.currency_id = response.data.data.currency_id;
        this.currency_default_id = response.data.data.currency_id;

        // if (response.data.data.branch_id){
        //   this.branch_default = response.data.data.branch;
        //   this.branches_ids.push(response.data.data.branch);
        //   this.getIds('branches');
        // }

      });
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/sales_status").then((response) => {
        this.status_list = response.data.data;
        //     let _allowed = [1,2,5];
        // this.status_list = response.data.data.filter((row)=>_allowed.includes(row.id));
        // let _status = response.data.data.find((row)=>row.id == 1 );
        // if(_status){
        //   this.status_ids.push(_status);
        //   this.filters.status_ids.push(_status.id);
        // }
        let _status = response.data.data.filter((row) => row.id == 2 || row.id == 5);
        if (_status) {
          this.status_ids.push(..._status);
          this.getIds('status');
        }
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },

    getIds(related_with) {

      switch (related_with) {
        case 'customers':
          this.filters.customers_ids = this.customers_ids.map((row) => row.id);
          this.filters.is_all_customers = 0;
          break;
        case 'branches':
          this.filters.branches_ids = this.branches_ids.map((row) => row.id);
          this.filters.is_all_branches = 0;
          break;
        case 'users':
          this.filters.user_id = this.user.id;
          break;
        case 'status':
          this.filters.status_ids = this.status_ids.map((row) => row.id);
          break;
        case 'inventories':
          this.filters.inventories_ids = this.inventories_ids.map((row) => row.id);
          break;
      }


    },

    getAllIds(related_with) {
      switch (related_with) {
        case 'customers':
          if (this.filters.is_all_customers == 1) {
            this.filters.customers_ids = [];
            this.customers_ids = [];
          }
          break;
        case 'branches':
          if (this.filters.is_all_branches == 1) {
            this.filters.branches_ids = [];
            this.branches_ids = [];
          }
          break;

      }
    },
    changeFinancialYear() {
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },

    changeListOfInvoiceType() {
      let _status = ['sales_invoice', 'general_sales_invoice', 'pos'];
      if (this.filters.only_invoices == 1) {

        this.status_ids = this.status_list.filter((row) => row.id == 2 || row.id == 5);
        this.filters.status_ids = [];
        this.status_ids.forEach((row) => {
          this.filters.status_ids.push(row.id);
        })


        this.filters.invoice_type = 'sales_invoice';
        this.invoice_type_list = this.invoice_type_list.map((row) => {
          if (!_status.includes(row.key)) {
            row.disabled = true
          }
          return row;
        });
      } else {
        this.invoice_type_list = this.invoice_type_list.map((row) => {
          row.disabled = false;
          return row;
        });
      }
    },
    loadMore() {
      this.page = this.page + 1;

      this.getReportDetails();
    },
    /*****
     * details
     */

    getReportDetails() {
      ApiService.get(this.mainRoute, {
        params: {
          ...this.filters,
          page: (this.page ? this.page : 1)
        }
      }).then((response) => {
        if (response.data.data.current_page == 1) {
          this.data_report_details = response.data.data.data;
        } else {
          this.data_report_details.push(...response.data.data.data);
        }
        this.current_page = response.data.data.current_page;
        this.last_page = response.data.data.last_page;
      });
    },
    /**
     * actions export
     * @param tableId
     * @param _export
     */
    getDataForExport(tableId, _export) {
      // ApiService.get(this.mainRoute, {params: {...this.filters, export: 1}}).then((response) => {
      //   this.dataList = response.data.data;
      this.dataList = [...this.data_report_details];

      setTimeout(() => {
        if (_export == 'pdf')
          this.printPDFData(tableId, _export);
        if (_export == 'print')
          this.printData(tableId);
        if (_export == 'excel')
          this.convertTableToExcelReport(tableId);

      }, 100)
      // });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, export: 1}}).then((response) => {
        this.dataList = response.data.data;

        // if (_export == 'pdf')
        //   this.printPDFData(tableId, _export);
        if (_export == 'print')
          this.printData(tableId);
        if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'customer transactions report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.customer_transactions_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    printData(tableId) {
      this.setReportLog('pdf', 'Customer Transactions Report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'customer transactions report');
      exportPDFInnerAction(tableId, type, this.$t('MENU.customer_transactions_report'), this.$t('MENU.customer_transactions_report'));
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
/*.export-container {*/
/*    overflow: auto;*/
/*    width: 1px;*/
/*    height: 1px;*/
/*}*/
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}
</style>
